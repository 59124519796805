import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

export const useRecaptcha = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const execute = async (action) => {
        if (!executeRecaptcha) {
            console.warn('Execute recaptcha not yet available');
            return null;
        }

        try {
            return await executeRecaptcha(action);
        } catch (error) {
            console.error('Recaptcha failed', error);
            return null;
        }
    };

    return { execute };
};