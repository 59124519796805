import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {isIOS, isSafari} from 'react-device-detect';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import PropTypes from 'prop-types';
import styles from './ClientsCertificates.module.css';
import localizations from '../../localizations/localizations';
import * as backPaths from '../../paths/backPaths';
import {ReactComponent as LoaderAnimation} from './loaderAnimation.svg';
import {ReactComponent as QuestionMarkIcon} from './questionMarkIcon.svg';
import {ReactComponent as ArrowBackIcon} from './arrowBackIcon.svg';
import {ReactComponent as SearchInfoIcon} from './searchInfoIcon.svg';
import {ReactComponent as JksSignIcon} from './jksSignIcon.svg';
import {ReactComponent as JksStampIcon} from './jksStampIcon.svg';
import {ReactComponent as SmartIdSignIcon} from './smartIdSignIcon.svg';
import {ReactComponent as SmartIdStampIcon} from './smartIdStampIcon.svg';
import {ReactComponent as DownloadCertificatesIcon} from './downloadCertificatesIcon.svg';
import {ReactComponent as DownloadPdfsIcon} from './downloadPdfsIcon.svg';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Tooltip from '../Tooltip/Tooltip';
import CheckBox from '../CheckBox/CheckBox';
import {useRecaptcha} from '../Recaptcha/Recaptcha';
import Headline from '../Headline/Headline';
import Row from '../Row/Row';

const innDebounce = 1000;
const edrpouDebounce = 1000;
const serialNumberDebounce = 1000;
const innPattern = '(^\\d{6,10}$)|(^[a-zA-Zа-яА-ЯёЁїЇіІєЄґҐ]{2,8}\\d{6,9}$)';
const edrpouPattern = '(^\\d{6,10}$)|(^[a-zA-Zа-яА-ЯёЁїЇіІєЄґҐ]{2,8}\\d{6,9}$)';
const serialNumberPattern = '^[a-zA-Z\\d]{20,42}$';
const certificateIndexPattern = 'certificate-{index}';
const maxSelectCertificates = 40;
const selectedCertificateBackgroundColor = 'var(--004-black)';
const shakeAnimationDuration = '300ms';
const shakeBorder = '2px solid var(--black)';
const shakeDefaultDuration = '0ms';
const shakeDefaultBorder = '';
const shakeDuration = 1000;
const shakeCheckedColor = 'var(--black)';
const shakeCheckedAnimationDuration = '300ms';
const shakeCheckedDefaultColor = '';
const shakeCheckedDefaultDuration = '0ms';
const shakeCheckedDuration = 1000;

let correctInn = true;
let inn = undefined;
let correctEdrpou = true;
let edrpou = undefined;
let correctSerialNumber = true;
let serialNumber = undefined;
let withRevoked = false;
let certificatesChecked = [];

function ClientsCertificates(props) {
    const {
        openCertificatesNavigation,
        closeCertificatesNavigation,
        errorGetClientCertificates,
        errorGetCertificates,
        errorGetPdf
    } = props;
    const [clientsCertificates, setClientsCertificates] = useState([]);
    const searchContainerRef = useRef();
    const inputInnRef = useRef();
    const inputEdrpouRef = useRef();
    const inputSerialNumberRef = useRef();
    const withRevokedCheckBoxRef = useRef();
    const searchRef = useRef();
    const contentContainerRef = useRef();
    const multiplyCheckBoxRef = useRef();
    const singleCheckBoxRefs = useRef([]);
    const checkedRef = useRef();
    const processingRef = useRef();
    let innTimer = null;
    let edrpouTimer = null;
    let serialNumberTimer = null;
    let shakeTimer = null;
    let shakeCheckedTimer = null;
    const {execute} = useRecaptcha();

    useEffect(() => {
        console.log('Clients certificates = ', clientsCertificates);
    }, [clientsCertificates]);
    useEffect(() => {
        if (clientsCertificates) {
            if (certificatesChecked.length >= clientsCertificates.length) {
                let shouldCheck = true;
                clientsCertificates.some(clientsCertificate => {
                    if (!certificatesChecked.includes(clientsCertificate.index)) {
                        shouldCheck = false;
                        return true;
                    } else {
                        return false;
                    }
                });
                if (shouldCheck) {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceCheck();
                } else {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceUncheck();
                }
            } else {
                if (certificatesChecked.length >= maxSelectCertificates) {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceCheck();
                } else {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceUncheck();
                }
            }
            certificatesChecked.forEach(certificateChecked => {
                singleCheckBoxRefs.current[certificateChecked] && singleCheckBoxRefs.current[certificateChecked].forceCheck();
                if (document.getElementById(certificateIndexPattern.replace('{index}', certificateChecked))) {
                    document.getElementById(certificateIndexPattern.replace('{index}', certificateChecked)).style.background = selectedCertificateBackgroundColor;
                }
            });
        }
    }, [clientsCertificates]);
    useEffect(() => {
        validateSearch();
        return () => {
            clearTimeout(shakeCheckedTimer);
            clearTimeout(shakeTimer);
            clearTimeout(serialNumberTimer);
            clearTimeout(edrpouTimer);
            clearTimeout(innTimer);
            openCertificatesNavigation();
            certificatesChecked = [];
            withRevoked = false;
            serialNumber = undefined;
            correctSerialNumber = true;
            edrpou = undefined;
            correctEdrpou = true;
            inn = undefined;
            correctInn = true;
        };
        // eslint-disable-next-line
    }, [shakeCheckedTimer, shakeTimer, serialNumberTimer, edrpouTimer, innTimer]);

    function onInputInn(event) {
        const {value} = event.target;
        clearTimeout(innTimer);
        event.persist();
        innTimer = setTimeout(() => {
            console.log('INN value - ', value);
            if (value.search(innPattern) === -1) {
                if (value.length === 0) {
                    correctInn = true;
                } else {
                    shake(inputInnRef.current);
                    correctInn = false;
                }
            } else {
                correctInn = true;
            }
            inn = value;
            validateSearch();
        }, innDebounce);
    }

    function onInputEdrpou(event) {
        const {value} = event.target;
        clearTimeout(edrpouTimer);
        event.persist();
        edrpouTimer = setTimeout(() => {
            console.log('EDRPOU value - ', value);
            if (value.search(edrpouPattern) === -1) {
                if (value.length === 0) {
                    correctEdrpou = true;
                } else {
                    shake(inputEdrpouRef.current);
                    correctEdrpou = false;
                }
            } else {
                correctEdrpou = true;
            }
            edrpou = value;
            validateSearch();
        }, edrpouDebounce);
    }

    function onInputSerialNumber(event) {
        const {value} = event.target;
        clearTimeout(serialNumberTimer);
        event.persist();
        serialNumberTimer = setTimeout(() => {
            console.log('Serial number value - ', value);
            if (value.search(serialNumberPattern) === -1) {
                if (value.length === 0) {
                    correctSerialNumber = true;
                } else {
                    shake(inputSerialNumberRef.current);
                    correctSerialNumber = false;
                }
            } else {
                correctSerialNumber = true;
            }
            serialNumber = value;
            validateSearch();
        }, serialNumberDebounce);
    }

    function onChangeWithRevokedCheckBox(event) {
        const {checked} = event.target;
        event.persist();
        console.log('With revoked - ', checked);
        withRevoked = checked;
        validateSearch();
    }


    async function onSearch() {
        processingRef.current.hidden = false;
        const recaptchaToken = await execute('submit');

        const request = {
            inn: inn,
            edrpou: edrpou,
            serial: serialNumber,
            revoked: withRevoked,
            captcha: recaptchaToken,
        };
        axios.post(backPaths.GET_CLIENT_CERTIFICATES_BACK_PATH, request)
            .then(response => {
                console.log('Get client certificates response = ', response);
                searchContainerRef.current.hidden = true;
                processingRef.current.hidden = true;
                closeCertificatesNavigation();
                const clientCertificates = response.data.sort((firstCertificate, secondCertificate) => {
                    return firstCertificate.index - secondCertificate.index;
                });
                setClientsCertificates(clientCertificates);
                contentContainerRef.current.hidden = false;
            })
            .catch(error => {
                console.log('Get client certificates response = ', error.response);
                processingRef.current.hidden = true;
                errorGetClientCertificates(1200, error.response.headers.requestid);
            });
    }

    function onBack() {
        searchContainerRef.current.hidden = false;
        openCertificatesNavigation();
        setClientsCertificates([]);
        contentContainerRef.current.hidden = true;
        certificatesChecked = [];
        validateSearch();
        if (checkedRef.current) {
            checkedRef.current.innerText = 0;
        }
    }

    function onChangeMultiCheckBox(event) {
        if (certificatesChecked.length < maxSelectCertificates || !event.target.checked) {
            let oldCertificatesCheckedLength = certificatesChecked.length;
            if (event.target.checked) {
                certificatesChecked.splice(0, certificatesChecked.length);
                for (let i = 0; i < (maxSelectCertificates >= clientsCertificates.length ? clientsCertificates.length : maxSelectCertificates); i++) {
                    certificatesChecked.push(clientsCertificates[i].index);
                }
                oldCertificatesCheckedLength = 0;
                singleCheckBoxRefs.current.forEach(singleCheckBoxRef => singleCheckBoxRef && singleCheckBoxRef.forceUncheck());
                clientsCertificates.forEach(clientsCertificate => {
                    if (document.getElementById(certificateIndexPattern.replace('{index}', clientsCertificate.index.toString()))) {
                        document.getElementById(certificateIndexPattern.replace('{index}', clientsCertificate.index.toString())).style.background = '';
                    }
                });
                let count = oldCertificatesCheckedLength;
                clientsCertificates.some(clientsCertificate => {
                    if (singleCheckBoxRefs.current[clientsCertificate.index]) {
                        singleCheckBoxRefs.current[clientsCertificate.index].forceCheck();
                        count++;
                        return count >= maxSelectCertificates;
                    } else {
                        return false;
                    }
                });
                count = oldCertificatesCheckedLength;
                clientsCertificates.some(clientsCertificate => {
                    if (document.getElementById(certificateIndexPattern.replace('{index}', clientsCertificate.index.toString()))) {
                        document.getElementById(certificateIndexPattern.replace('{index}', clientsCertificate.index.toString())).style.background = selectedCertificateBackgroundColor;
                        count++;
                        return count >= maxSelectCertificates;
                    } else {
                        return false;
                    }
                });
            } else {
                certificatesChecked.splice(0, certificatesChecked.length);
                singleCheckBoxRefs.current.forEach(singleCheckBoxRef => singleCheckBoxRef && singleCheckBoxRef.forceUncheck());
                clientsCertificates.forEach(clientsCertificate => {
                    if (document.getElementById(certificateIndexPattern.replace('{index}', clientsCertificate.index.toString()))) {
                        document.getElementById(certificateIndexPattern.replace('{index}', clientsCertificate.index.toString())).style.background = '';
                    }
                });
            }
            if (checkedRef.current) {
                checkedRef.current.innerText = certificatesChecked.length;
            }
        } else {
            if (certificatesChecked.length >= clientsCertificates.length) {
                let shouldCheck = true;
                clientsCertificates.some(clientsCertificate => {
                    if (!certificatesChecked.includes(clientsCertificate.index)) {
                        shouldCheck = false;
                        return true;
                    } else {
                        return false;
                    }
                });
                if (shouldCheck) {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceCheck();
                } else {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceUncheck();
                }
            } else {
                if (certificatesChecked.length >= maxSelectCertificates) {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceCheck();
                } else {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceUncheck();
                }
            }
            shakeChecked();
        }
    }

    function onChangeSingleCheckBox(event, certificateIndex) {
        if (certificatesChecked.length < maxSelectCertificates || !event.target.checked) {
            if (certificatesChecked.includes(certificateIndex)) {
                certificatesChecked.splice(certificatesChecked.indexOf(certificateIndex), 1);
                document.getElementById(certificateIndexPattern.replace('{index}', certificateIndex)).style.background = '';
            } else {
                certificatesChecked.push(certificateIndex);
                document.getElementById(certificateIndexPattern.replace('{index}', certificateIndex)).style.background = selectedCertificateBackgroundColor;
            }
            if (certificatesChecked.length >= clientsCertificates.length) {
                let shouldCheck = true;
                clientsCertificates.some(clientsCertificate => {
                    if (!certificatesChecked.includes(clientsCertificate.index)) {
                        shouldCheck = false;
                        return true;
                    } else {
                        return false;
                    }
                });
                if (shouldCheck) {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceCheck();
                } else {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceUncheck();
                }
            } else {
                if (certificatesChecked.length >= maxSelectCertificates) {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceCheck();
                } else {
                    multiplyCheckBoxRef.current && multiplyCheckBoxRef.current.forceUncheck();
                }
            }
            if (checkedRef.current) {
                checkedRef.current.innerText = certificatesChecked.length;
            }
        } else {
            singleCheckBoxRefs.current[certificateIndex] && singleCheckBoxRefs.current[certificateIndex].forceUncheck();
            shakeChecked();
        }
    }

    function onDownloadCertificates() {
        if (certificatesChecked.length === 0 || certificatesChecked.length > maxSelectCertificates) {
            shakeChecked();
        } else {
            if (isIOS && !isSafari) {
                errorGetCertificates(1300);
            } else {
                processingRef.current.hidden = false;
                setTimeout(() => {
                    const jsZip = new JSZip();
                    const getCertificatesRequestsPromises = [];
                    certificatesChecked.forEach(certificateChecked => {
                        const request = {
                            serial: clientsCertificates[certificateChecked - 1].serial,
                        };
                        const getCertificatesRequestPromise = axios.post(backPaths.GET_CERTIFICATES_BACK_PATH, request)
                            .then(response => {
                                console.log('Get certificates response = ', response);
                                if (response.data.sign) {
                                    const signCertificateName = response.data.serial + '_sign.crt';
                                    const signCertificateData = response.data.sign;
                                    jsZip.file(signCertificateName, signCertificateData, {base64: true});
                                }
                                if (response.data.enc) {
                                    const encCertificateName = response.data.serialEnc + '_enc.crt';
                                    const encCertificateData = response.data.enc;
                                    jsZip.file(encCertificateName, encCertificateData, {base64: true});
                                }
                            })
                            .catch(error => {
                                console.log('Get certificates response = ', error.response);
                                return Promise.reject({
                                    code: 1310,
                                    requestId: error.response.headers.requestid,
                                });
                            });
                        getCertificatesRequestsPromises.push(getCertificatesRequestPromise);
                    });
                    Promise.all(getCertificatesRequestsPromises)
                        .then(() => {
                            jsZip.generateAsync({type: 'blob'})
                                .then(zip => {
                                    saveAs(zip, 'certificates.zip');
                                    console.log('Certificates downloaded');
                                    processingRef.current.hidden = true;
                                })
                                .catch(error => {
                                    console.log('Generate certificates zip error = ', error);
                                    errorGetCertificates(1320);
                                    processingRef.current.hidden = true;
                                });
                        })
                        .catch(error => {
                            errorGetCertificates(error.code, error.requestId);
                            processingRef.current.hidden = true;
                        });
                }, 25);
            }
        }
    }

    function onDownloadPdfs() {
        if (certificatesChecked.length === 0 || certificatesChecked.length > maxSelectCertificates) {
            shakeChecked();
        } else {
            if (isIOS && !isSafari) {
                errorGetPdf(1400);
            } else {
                processingRef.current.hidden = false;
                setTimeout(() => {
                    const jsZip = new JSZip();
                    const getPdfRequestsPromises = [];
                    certificatesChecked.forEach(certificateChecked => {
                        const request = {
                            serial: clientsCertificates[certificateChecked - 1].serial,
                        };
                        const getPdfRequestPromise = axios.post(backPaths.GET_PDF_BACK_PATH, request)
                            .then(response => {
                                console.log('Get pdf response = ', response);
                                if (response.data.pdf) {
                                    const pdfName = request.serial + '.pdf';
                                    const pdfData = response.data.pdf;
                                    jsZip.file(pdfName, pdfData, {base64: true});
                                }
                            })
                            .catch(error => {
                                console.log('Get pdf response = ', error.response);
                                return Promise.reject({
                                    code: 1410,
                                    requestId: error.response.headers.requestid,
                                });
                            });
                        getPdfRequestsPromises.push(getPdfRequestPromise);
                    });
                    Promise.all(getPdfRequestsPromises)
                        .then(() => {
                            jsZip.generateAsync({type: 'blob'})
                                .then(zip => {
                                    saveAs(zip, 'pdfs.zip');
                                    console.log('PDFs downloaded');
                                    processingRef.current.hidden = true;
                                })
                                .catch(error => {
                                    console.log('Generate pdfs zip error = ', error);
                                    errorGetPdf(1420);
                                    processingRef.current.hidden = true;
                                });
                        })
                        .catch(error => {
                            errorGetPdf(error.code, error.requestId);
                            processingRef.current.hidden = true;
                        });
                }, 25);
            }
        }
    }

    function shake(element) {
        element.style.animationDuration = shakeAnimationDuration;
        element.style.border = shakeBorder;
        clearTimeout(shakeTimer);
        shakeTimer = setTimeout(() => {
            element.style.animationDuration = shakeDefaultDuration;
            element.style.border = shakeDefaultBorder;
        }, shakeDuration);
    }

    function shakeChecked() {
        for (let i = 0; i < checkedRef.current.parentElement.children.length; i++) {
            checkedRef.current.parentElement.children[i].style.color = shakeCheckedColor;
        }
        checkedRef.current.parentElement.style.animationDuration = shakeCheckedAnimationDuration;
        clearTimeout(shakeCheckedTimer);
        shakeCheckedTimer = setTimeout(() => {
            for (let i = 0; i < checkedRef.current.parentElement.children.length; i++) {
                checkedRef.current.parentElement.children[i].style.color = shakeCheckedDefaultColor;
            }
            checkedRef.current.parentElement.style.animationDuration = shakeCheckedDefaultDuration;
        }, shakeCheckedDuration);
    }

    function validateSearch() {
        searchRef.current.disabled = !((inn || edrpou || serialNumber) && (correctInn && correctEdrpou && correctSerialNumber));
    }

    return (
        <div className={styles.ClientsCertificates}>
            <BrowserView viewClassName={styles.Browser}>
                <div ref={searchContainerRef}
                     className={styles.SearchContainer}>
                    <div className={styles.Inputs}>
                        <div className={styles.InputContainer}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}
                                      contentColor={'var(--070-black)'}>
                                {localizations.clientsCertificatesInn}
                                &nbsp;
                                <Tooltip
                                    messages={[localizations.clientsCertificatesInnTooltip1, localizations.clientsCertificatesInnTooltip2]}
                                    placement={'top'}>
                                    <QuestionMarkIcon className={styles.Icon}/>
                                </Tooltip>
                            </Headline>
                            <input ref={inputInnRef}
                                   className={styles.Input}
                                   placeholder={localizations.clientsCertificatesInnInputPlaceholder}
                                   onChange={onInputInn}/>
                        </div>
                        <div className={styles.InputContainer}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}
                                      contentColor={'var(--070-black)'}>
                                {localizations.clientsCertificatesEdrpou}
                                &nbsp;
                                <Tooltip
                                    messages={[localizations.clientsCertificatesEdrpouTooltip1, localizations.clientsCertificatesEdrpouTooltip2]}
                                    placement={'top'}>
                                    <QuestionMarkIcon className={styles.Icon}/>
                                </Tooltip>
                            </Headline>
                            <input ref={inputEdrpouRef}
                                   className={styles.Input}
                                   placeholder={localizations.clientsCertificatesEdrpouInputPlaceholder}
                                   onChange={onInputEdrpou}/>
                        </div>
                        <div className={styles.InputContainer}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}
                                      contentColor={'var(--070-black)'}>
                                {localizations.clientsCertificatesSerialNumber}
                            </Headline>
                            <input ref={inputSerialNumberRef}
                                   className={styles.Input}
                                   placeholder={localizations.clientsCertificatesSerialNumberInputPlaceholder}
                                   onChange={onInputSerialNumber}/>
                        </div>
                    </div>
                    <div className={styles.CheckBoxContainer}>
                        <CheckBox id={-1}
                                  ref={withRevokedCheckBoxRef}
                                  onChange={onChangeWithRevokedCheckBox}/>
                        <Row horizontal={true}
                             contentMargin={'0 0 0 15px'}
                             contentFontSize={'var(--body1-font-size)'}
                             contentLineHeight={'var(--body1-line-height)'}>
                            {localizations.clientsCertificatesWithRevoked}
                        </Row>
                    </div>
                    <div className={styles.Row}>
                        <Row topLine={true}
                             disabledIcon={true}/>
                    </div>
                    <div className={styles.Search}>
                        <button ref={searchRef}
                                className={styles.Button}
                                onClick={onSearch}>
                            {localizations.clientsCertificatesSearch}
                        </button>
                    </div>
                </div>
                <div ref={contentContainerRef}
                     className={styles.ContentContainer}
                     hidden={true}>
                    <div className={styles.Back}
                         onClick={onBack}>
                        <ArrowBackIcon className={styles.Icon}/>
                        <Headline contentMargin={'0 0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body1-bold-font-weight)'}
                                  contentFontSize={'var(--body1-bold-font-size)'}
                                  contentLineHeight={'var(--body1-bold-line-height)'}
                                  contentColor={'var(--090-black)'}>
                            {localizations.clientsCertificatesBack}
                        </Headline>
                    </div>
                    <Headline contentMargin={'30px 0 0'}
                              contentFontSize={'var(--display2-font-size)'}
                              contentLineHeight={'var(--display2-line-height)'}>
                        {localizations.clientsCertificatesFound.replaceAll('{size}', clientsCertificates.length)}
                    </Headline>
                    <div className={styles.SearchInfoContainer}>
                        {
                            inn && <div className={styles.SearchInfo}>
                                <SearchInfoIcon className={styles.Icon}/>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.clientsCertificatesSearchInfoInn.replaceAll('{inn}', inn)}
                                </Row>
                            </div>
                        }
                        {
                            edrpou && <div className={styles.SearchInfo}>
                                <SearchInfoIcon className={styles.Icon}/>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.clientsCertificatesSearchInfoEdrpou.replaceAll('{edrpou}', edrpou)}
                                </Row>
                            </div>
                        }
                        {
                            serialNumber && <div className={styles.SearchInfo}>
                                <SearchInfoIcon className={styles.Icon}/>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.clientsCertificatesSearchInfoSerialNumber.replaceAll('{serialNumber}', serialNumber)}
                                </Row>
                            </div>
                        }
                        {
                            withRevoked && <div className={styles.SearchInfo}>
                                <SearchInfoIcon className={styles.Icon}/>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.clientsCertificatesSearchInfoWithRevoked}
                                </Row>
                            </div>
                        }
                    </div>
                    <div className={styles.Content}>
                        {
                            clientsCertificates.length > 0 && <table className={styles.ClientsCertificate}>
                                <thead>
                                <tr>
                                    <th>
                                        <Tooltip messages={[localizations.clientsCertificatesMultiCheckBox]}
                                                 placement={'top'}>
                                            <div className={styles.MultiCheckBoxContainer}>
                                                <CheckBox id={0}
                                                          ref={multiplyCheckBoxRef}
                                                          onChange={onChangeMultiCheckBox}/>
                                            </div>
                                        </Tooltip>
                                    </th>
                                    <th>
                                        <Headline contentMargin={'0'}
                                                  contentFontFamily={'Inter, sans-serif'}
                                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                                  contentFontSize={'var(--body2-bold-font-size)'}
                                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                            {localizations.clientsCertificatesOwner}
                                        </Headline>
                                    </th>
                                    <th>
                                        <Headline contentMargin={'0'}
                                                  contentFontFamily={'Inter, sans-serif'}
                                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                                  contentFontSize={'var(--body2-bold-font-size)'}
                                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                            {localizations.clientsCertificatesSerial}
                                        </Headline>
                                    </th>
                                    <th>
                                        <Headline contentMargin={'0'}
                                                  contentFontFamily={'Inter, sans-serif'}
                                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                                  contentFontSize={'var(--body2-bold-font-size)'}
                                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                            {localizations.clientsCertificatesStatus}
                                        </Headline>
                                    </th>
                                    <th>
                                        <Headline contentMargin={'0'}
                                                  contentFontFamily={'Inter, sans-serif'}
                                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                                  contentFontSize={'var(--body2-bold-font-size)'}
                                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                            {localizations.clientsCertificatesValidity}
                                        </Headline>
                                    </th>
                                    <th>
                                        <Headline contentMargin={'0'}
                                                  contentFontFamily={'Inter, sans-serif'}
                                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                                  contentFontSize={'var(--body2-bold-font-size)'}
                                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                            {localizations.clientsCertificatesType}
                                        </Headline>
                                    </th>
                                    <th>
                                        <Headline contentMargin={'0'}
                                                  contentFontFamily={'Inter, sans-serif'}
                                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                                  contentFontSize={'var(--body2-bold-font-size)'}
                                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                            {localizations.clientsCertificatesKey}
                                        </Headline>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    clientsCertificates.map(displayedClientsCertificate => {
                                        return (
                                            <tr id={certificateIndexPattern.replace('{index}', displayedClientsCertificate.index.toString())}
                                                key={displayedClientsCertificate.index}>
                                                <td>
                                                    <CheckBox id={displayedClientsCertificate.index}
                                                              ref={(element) => singleCheckBoxRefs.current[displayedClientsCertificate.index] = element}
                                                              onChange={(event) => onChangeSingleCheckBox(event, displayedClientsCertificate.index)}/>
                                                </td>
                                                <td>
                                                    <Row horizontal={true}
                                                         contentFontSize={'var(--body2-font-size)'}
                                                         contentLineHeight={'var(--body2-line-height)'}>
                                                        {displayedClientsCertificate.owner}
                                                    </Row>
                                                    <Row horizontal={true}
                                                         contentFontSize={'var(--body2-font-size)'}
                                                         contentLineHeight={'var(--body2-line-height)'}>
                                                        {displayedClientsCertificate.orgName}
                                                    </Row>
                                                </td>
                                                <td>
                                                    <Row horizontal={true}
                                                         contentFontSize={'var(--body2-font-size)'}
                                                         contentLineHeight={'var(--body2-line-height)'}>
                                                        {displayedClientsCertificate.serial}
                                                    </Row>
                                                </td>
                                                <td>
                                                    <div style={{flexDirection: 'column'}}
                                                         className={styles.Flex}>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {
                                                                (
                                                                    displayedClientsCertificate.status === 3
                                                                        ? localizations.clientsCertificatesStatusBlocked
                                                                        : displayedClientsCertificate.status === 2
                                                                            ? localizations.clientsCertificatesStatusRevoked
                                                                            : displayedClientsCertificate.status === 4
                                                                                ? localizations.clientsCertificatesStatusExpired
                                                                                : localizations.clientsCertificatesStatusActive
                                                                ).split('\n').map((line, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {line}
                                                                        <br/>
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </Row>
                                                        {
                                                            displayedClientsCertificate.isRevoked && displayedClientsCertificate.status !== 4 && (
                                                                <Row
                                                                    horizontal={true}
                                                                    contentFontSize="var(--body2-font-size)"
                                                                    contentLineHeight="var(--body2-line-height)">
                                                                    {displayedClientsCertificate.revokedDate}
                                                                </Row>
                                                            )
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className={styles.Flex}>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {displayedClientsCertificate.startDate}
                                                        </Row>
                                                        &nbsp;
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {displayedClientsCertificate.endDate}
                                                        </Row>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className={styles.Flex}>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {
                                                                displayedClientsCertificate.isSign
                                                                    ? localizations.clientsCertificatesTypeSign
                                                                    : localizations.clientsCertificatesTypeStamp
                                                            }
                                                        </Row>
                                                    </div>
                                                </td>
                                                <td>
                                                    {
                                                        displayedClientsCertificate.type === 2
                                                            ? displayedClientsCertificate.isSign
                                                                ? <Tooltip
                                                                    messages={[localizations.clientsCertificatesSignSmartID]}
                                                                    placement={'top'}>
                                                                    <SmartIdSignIcon className={styles.Icon}/>
                                                                </Tooltip>
                                                                : <Tooltip
                                                                    messages={[localizations.clientsCertificatesStampSmartID]}
                                                                    placement={'top'}>
                                                                    <SmartIdStampIcon className={styles.Icon}/>
                                                                </Tooltip>
                                                            : displayedClientsCertificate.isSign
                                                                ? <Tooltip
                                                                    messages={[localizations.clientsCertificatesSignJKS]}
                                                                    placement={'top'}>
                                                                    <JksSignIcon className={styles.Icon}/>
                                                                </Tooltip>
                                                                :
                                                                <Tooltip
                                                                    messages={[localizations.clientsCertificatesStampJKS]}
                                                                    placement={'top'}>
                                                                    <JksStampIcon className={styles.Icon}/>
                                                                </Tooltip>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                                </tbody>
                            </table>
                        }
                    </div>
                    <div className={styles.ActionPanel}>
                        <Row topLine={true}
                             disabledIcon={true}/>
                        <div className={styles.Container}>
                            <div className={styles.Shake}>
                                    <span className={styles.Checked}>
                                        {localizations.clientsCertificatesChecked}
                                    </span>
                                <span>&nbsp;</span>
                                <span ref={checkedRef}
                                      className={styles.Checked}>
                                        {certificatesChecked ? certificatesChecked.length : 0}
                                    </span>
                                <span>&nbsp;</span>
                                <span className={styles.CheckedMax}>
                                        {localizations.clientsCertificatesCheckedMax.replaceAll('{max}', maxSelectCertificates.toString())}
                                    </span>
                            </div>
                            <div className={styles.ActionsMenu}>
                                <div className={styles.Action}
                                     onClick={onDownloadCertificates}>
                                    <DownloadCertificatesIcon className={styles.Icon}/>
                                    <Row horizontal={true}
                                         contentFontSize={'var(--body2-font-size)'}
                                         contentLineHeight={'var(--body2-line-height)'}>
                                        {localizations.clientsCertificatesDownloadCertificatesAction}
                                    </Row>
                                </div>
                                <div className={styles.Action}
                                     onClick={onDownloadPdfs}>
                                    <DownloadPdfsIcon className={styles.Icon}/>
                                    <Row horizontal={true}
                                         contentFontSize={'var(--body2-font-size)'}
                                         contentLineHeight={'var(--body2-line-height)'}>
                                        {localizations.clientsCertificatesDownloadPdfsAction}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={processingRef}
                     className={styles.Processing}
                     hidden={true}>
                    <div className={styles.Blur}>
                        <LoaderAnimation className={styles.Animation}/>
                    </div>
                </div>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <div ref={searchContainerRef}
                     className={styles.SearchContainer}>
                    <div className={styles.Inputs}>
                        <div className={styles.InputContainer}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}
                                      contentColor={'var(--070-black)'}>
                                {localizations.clientsCertificatesInn}
                                &nbsp;
                                <Tooltip
                                    messages={[localizations.clientsCertificatesInnTooltip1, localizations.clientsCertificatesInnTooltip2]}
                                    placement={'top'}>
                                    <QuestionMarkIcon className={styles.Icon}/>
                                </Tooltip>
                            </Headline>
                            <input ref={inputInnRef}
                                   className={styles.Input}
                                   placeholder={localizations.clientsCertificatesInnInputPlaceholder}
                                   onChange={onInputInn}/>
                        </div>
                        <div className={styles.InputContainer}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}
                                      contentColor={'var(--070-black)'}>
                                {localizations.clientsCertificatesEdrpou}
                                &nbsp;
                                <Tooltip
                                    messages={[localizations.clientsCertificatesEdrpouTooltip1, localizations.clientsCertificatesEdrpouTooltip2]}
                                    placement={'top'}>
                                    <QuestionMarkIcon className={styles.Icon}/>
                                </Tooltip>
                            </Headline>
                            <input ref={inputEdrpouRef}
                                   className={styles.Input}
                                   placeholder={localizations.clientsCertificatesEdrpouInputPlaceholder}
                                   onChange={onInputEdrpou}/>
                        </div>
                        <div className={styles.InputContainer}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}
                                      contentColor={'var(--070-black)'}>
                                {localizations.clientsCertificatesSerialNumber}
                            </Headline>
                            <input ref={inputSerialNumberRef}
                                   className={styles.Input}
                                   placeholder={localizations.clientsCertificatesSerialNumberInputPlaceholder}
                                   onChange={onInputSerialNumber}/>
                        </div>
                    </div>
                    <div className={styles.CheckBoxContainer}>
                        <CheckBox id={-1}
                                  ref={withRevokedCheckBoxRef}
                                  onChange={onChangeWithRevokedCheckBox}/>
                        <Row horizontal={true}
                             contentMargin={'0 0 0 15px'}
                             contentFontSize={'var(--body1-font-size)'}
                             contentLineHeight={'var(--body1-line-height)'}>
                            {localizations.clientsCertificatesWithRevoked}
                        </Row>
                    </div>
                    <div className={styles.Row}>
                        <Row topLine={true}
                             disabledIcon={true}/>
                    </div>
                    <div className={styles.Search}>
                        <button ref={searchRef}
                                className={styles.Button}
                                onClick={onSearch}>
                            {localizations.clientsCertificatesSearch}
                        </button>
                    </div>
                </div>
                <div ref={contentContainerRef}
                     className={styles.ContentContainer}
                     hidden={true}>
                    <div className={styles.Back}
                         onClick={onBack}>
                        <ArrowBackIcon className={styles.Icon}/>
                        <Headline contentMargin={'0 0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body1-bold-font-weight)'}
                                  contentFontSize={'var(--body1-bold-font-size)'}
                                  contentLineHeight={'var(--body1-bold-line-height)'}
                                  contentColor={'var(--090-black)'}>
                            {localizations.clientsCertificatesBack}
                        </Headline>
                    </div>
                    <Headline contentMargin={'30px 0 0'}
                              contentFontSize={'var(--display2-font-size)'}
                              contentLineHeight={'var(--display2-line-height)'}>
                        {localizations.clientsCertificatesFound.replaceAll('{size}', clientsCertificates.length)}
                    </Headline>
                    <div className={styles.SearchInfoContainer}>
                        {
                            inn && <div className={styles.SearchInfo}>
                                <SearchInfoIcon className={styles.Icon}/>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.clientsCertificatesSearchInfoInn.replaceAll('{inn}', inn)}
                                </Row>
                            </div>
                        }
                        {
                            edrpou && <div className={styles.SearchInfo}>
                                <SearchInfoIcon className={styles.Icon}/>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.clientsCertificatesSearchInfoEdrpou.replaceAll('{edrpou}', edrpou)}
                                </Row>
                            </div>
                        }
                        {
                            serialNumber && <div className={styles.SearchInfo}>
                                <SearchInfoIcon className={styles.Icon}/>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.clientsCertificatesSearchInfoSerialNumber.replaceAll('{serialNumber}', serialNumber)}
                                </Row>
                            </div>
                        }
                        {
                            withRevoked && <div className={styles.SearchInfo}>
                                <SearchInfoIcon className={styles.Icon}/>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.clientsCertificatesSearchInfoWithRevoked}
                                </Row>
                            </div>
                        }
                    </div>
                    <div className={styles.Content}>
                        {
                            clientsCertificates.length > 0 && <table className={styles.ClientsCertificate}>
                                <thead>
                                <tr>
                                    <th>
                                        <Tooltip messages={[localizations.clientsCertificatesMultiCheckBox]}
                                                 placement={'top'}>
                                            <div className={styles.MultiCheckBoxContainer}>
                                                <CheckBox id={0}
                                                          ref={multiplyCheckBoxRef}
                                                          onChange={onChangeMultiCheckBox}/>
                                            </div>
                                        </Tooltip>
                                    </th>
                                    <th>
                                        <Headline contentMargin={'0'}
                                                  contentFontFamily={'Inter, sans-serif'}
                                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                                  contentFontSize={'var(--body2-bold-font-size)'}
                                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                            {localizations.clientsCertificatesOwner}
                                        </Headline>
                                    </th>
                                    <th>
                                        <Headline contentMargin={'0'}
                                                  contentFontFamily={'Inter, sans-serif'}
                                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                                  contentFontSize={'var(--body2-bold-font-size)'}
                                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                            {localizations.clientsCertificatesSerial}
                                        </Headline>
                                    </th>
                                    <th>
                                        <Headline contentMargin={'0'}
                                                  contentFontFamily={'Inter, sans-serif'}
                                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                                  contentFontSize={'var(--body2-bold-font-size)'}
                                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                            {localizations.clientsCertificatesStatus}
                                        </Headline>
                                    </th>
                                    <th>
                                        <Headline contentMargin={'0'}
                                                  contentFontFamily={'Inter, sans-serif'}
                                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                                  contentFontSize={'var(--body2-bold-font-size)'}
                                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                            {localizations.clientsCertificatesValidity}
                                        </Headline>
                                    </th>
                                    <th>
                                        <Headline contentMargin={'0'}
                                                  contentFontFamily={'Inter, sans-serif'}
                                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                                  contentFontSize={'var(--body2-bold-font-size)'}
                                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                            {localizations.clientsCertificatesType}
                                        </Headline>
                                    </th>
                                    <th>
                                        <Headline contentMargin={'0'}
                                                  contentFontFamily={'Inter, sans-serif'}
                                                  contentFontWeight={'var(--body2-bold-font-weight)'}
                                                  contentFontSize={'var(--body2-bold-font-size)'}
                                                  contentLineHeight={'var(--body2-bold-line-height)'}
                                                  contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                            {localizations.clientsCertificatesKey}
                                        </Headline>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    clientsCertificates.map(displayedClientsCertificate => {
                                        return (
                                            <tr id={certificateIndexPattern.replace('{index}', displayedClientsCertificate.index.toString())}
                                                key={displayedClientsCertificate.index}>
                                                <td>
                                                    <CheckBox id={displayedClientsCertificate.index}
                                                              ref={(element) => singleCheckBoxRefs.current[displayedClientsCertificate.index] = element}
                                                              onChange={(event) => onChangeSingleCheckBox(event, displayedClientsCertificate.index)}/>
                                                </td>
                                                <td>
                                                    <Row horizontal={true}
                                                         contentFontSize={'var(--body2-font-size)'}
                                                         contentLineHeight={'var(--body2-line-height)'}>
                                                        {displayedClientsCertificate.owner}
                                                    </Row>
                                                    <Row horizontal={true}
                                                         contentFontSize={'var(--body2-font-size)'}
                                                         contentLineHeight={'var(--body2-line-height)'}>
                                                        {displayedClientsCertificate.orgName}
                                                    </Row>
                                                </td>
                                                <td>
                                                    <Row horizontal={true}
                                                         contentFontSize={'var(--body2-font-size)'}
                                                         contentLineHeight={'var(--body2-line-height)'}>
                                                        {displayedClientsCertificate.serial}
                                                    </Row>
                                                </td>
                                                <td>
                                                    <div style={{flexDirection: 'column'}}
                                                         className={styles.Flex}>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {
                                                                (
                                                                    displayedClientsCertificate.status === 3
                                                                        ? localizations.clientsCertificatesStatusBlocked
                                                                        : displayedClientsCertificate.status === 2
                                                                            ? localizations.clientsCertificatesStatusRevoked
                                                                            : displayedClientsCertificate.status === 4
                                                                                ? localizations.clientsCertificatesStatusExpired
                                                                                : localizations.clientsCertificatesStatusActive
                                                                ).split('\n').map((line, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {line}
                                                                        <br/>
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </Row>
                                                        {
                                                            displayedClientsCertificate.isRevoked && displayedClientsCertificate.status !== 4 && (
                                                                <Row
                                                                    horizontal={true}
                                                                    contentFontSize="var(--body2-font-size)"
                                                                    contentLineHeight="var(--body2-line-height)">
                                                                    {displayedClientsCertificate.revokedDate}
                                                                </Row>
                                                            )
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className={styles.Flex}>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {displayedClientsCertificate.startDate}
                                                        </Row>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {displayedClientsCertificate.endDate}
                                                        </Row>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className={styles.Flex}>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {
                                                                displayedClientsCertificate.isSign
                                                                    ? localizations.clientsCertificatesTypeSign
                                                                    : localizations.clientsCertificatesTypeStamp
                                                            }
                                                        </Row>
                                                    </div>
                                                </td>
                                                <td>
                                                    {
                                                        displayedClientsCertificate.type === 2
                                                            ? displayedClientsCertificate.isSign
                                                                ? <Tooltip
                                                                    messages={[localizations.clientsCertificatesSignSmartID]}
                                                                    placement={'top'}>
                                                                    <SmartIdSignIcon className={styles.Icon}/>
                                                                </Tooltip>
                                                                : <Tooltip
                                                                    messages={[localizations.clientsCertificatesStampSmartID]}
                                                                    placement={'top'}>
                                                                    <SmartIdStampIcon className={styles.Icon}/>
                                                                </Tooltip>
                                                            : displayedClientsCertificate.isSign
                                                                ? <Tooltip
                                                                    messages={[localizations.clientsCertificatesSignJKS]}
                                                                    placement={'top'}>
                                                                    <JksSignIcon className={styles.Icon}/>
                                                                </Tooltip>
                                                                :
                                                                <Tooltip
                                                                    messages={[localizations.clientsCertificatesStampJKS]}
                                                                    placement={'top'}>
                                                                    <JksStampIcon className={styles.Icon}/>
                                                                </Tooltip>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                                </tbody>
                            </table>
                        }
                    </div>
                    <div className={styles.ActionPanel}>
                        <Row topLine={true}
                             disabledIcon={true}/>
                        <div className={styles.Container}>
                            <div className={styles.Shake}>
                                    <span className={styles.Checked}>
                                        {localizations.clientsCertificatesChecked}
                                    </span>
                                <span>&nbsp;</span>
                                <span ref={checkedRef}
                                      className={styles.Checked}>
                                        {certificatesChecked ? certificatesChecked.length : 0}
                                    </span>
                                <span>&nbsp;</span>
                                <span className={styles.CheckedMax}>
                                        {localizations.clientsCertificatesCheckedMax.replaceAll('{max}', maxSelectCertificates.toString())}
                                    </span>
                            </div>
                            <div className={styles.ActionsMenu}>
                                <div className={styles.Action}
                                     onClick={onDownloadCertificates}>
                                    <DownloadCertificatesIcon className={styles.Icon}/>
                                    <Row horizontal={true}
                                         contentFontSize={'var(--body2-font-size)'}
                                         contentLineHeight={'var(--body2-line-height)'}>
                                        {localizations.clientsCertificatesDownloadCertificatesAction}
                                    </Row>
                                </div>
                                <div className={styles.Action}
                                     onClick={onDownloadPdfs}>
                                    <DownloadPdfsIcon className={styles.Icon}/>
                                    <Row horizontal={true}
                                         contentFontSize={'var(--body2-font-size)'}
                                         contentLineHeight={'var(--body2-line-height)'}>
                                        {localizations.clientsCertificatesDownloadPdfsAction}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={processingRef}
                     className={styles.Processing}
                     hidden={true}>
                    <div className={styles.Blur}>
                        <LoaderAnimation className={styles.Animation}/>
                    </div>
                </div>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <div ref={searchContainerRef}
                     className={styles.SearchContainer}>
                    <div className={styles.Inputs}>
                        <div className={styles.InputContainer}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}
                                      contentColor={'var(--070-black)'}>
                                {localizations.clientsCertificatesInn}
                                &nbsp;
                                <Tooltip
                                    messages={[localizations.clientsCertificatesInnTooltip1, localizations.clientsCertificatesInnTooltip2]}
                                    placement={'top'}>
                                    <QuestionMarkIcon className={styles.Icon}/>
                                </Tooltip>
                            </Headline>
                            <input ref={inputInnRef}
                                   className={styles.Input}
                                   placeholder={localizations.clientsCertificatesInnInputPlaceholder}
                                   onChange={onInputInn}/>
                        </div>
                        <div className={styles.InputContainer}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}
                                      contentColor={'var(--070-black)'}>
                                {localizations.clientsCertificatesEdrpou}
                                &nbsp;
                                <Tooltip
                                    messages={[localizations.clientsCertificatesEdrpouTooltip1, localizations.clientsCertificatesEdrpouTooltip2]}
                                    placement={'top'}>
                                    <QuestionMarkIcon className={styles.Icon}/>
                                </Tooltip>
                            </Headline>
                            <input ref={inputEdrpouRef}
                                   className={styles.Input}
                                   placeholder={localizations.clientsCertificatesEdrpouInputPlaceholder}
                                   onChange={onInputEdrpou}/>
                        </div>
                        <div className={styles.InputContainer}>
                            <Headline contentMargin={'0'}
                                      contentFontFamily={'Inter, sans-serif'}
                                      contentFontWeight={'var(--body2-bold-font-weight)'}
                                      contentFontSize={'var(--body2-bold-font-size)'}
                                      contentLineHeight={'var(--body2-bold-line-height)'}
                                      contentLetterSpacing={'var(--body2-bold-letter-spacing)'}
                                      contentColor={'var(--070-black)'}>
                                {localizations.clientsCertificatesSerialNumber}
                            </Headline>
                            <input ref={inputSerialNumberRef}
                                   className={styles.Input}
                                   placeholder={localizations.clientsCertificatesSerialNumberInputPlaceholder}
                                   onChange={onInputSerialNumber}/>
                        </div>
                    </div>
                    <div className={styles.CheckBoxContainer}>
                        <CheckBox id={-1}
                                  ref={withRevokedCheckBoxRef}
                                  onChange={onChangeWithRevokedCheckBox}/>
                        <Row horizontal={true}
                             contentMargin={'0 0 0 15px'}
                             contentFontSize={'var(--body1-font-size)'}
                             contentLineHeight={'var(--body1-line-height)'}>
                            {localizations.clientsCertificatesWithRevoked}
                        </Row>
                    </div>
                    <div className={styles.Row}>
                        <Row topLine={true}
                             disabledIcon={true}/>
                    </div>
                    <div className={styles.Search}>
                        <button ref={searchRef}
                                className={styles.Button}
                                onClick={onSearch}>
                            {localizations.clientsCertificatesSearch}
                        </button>
                    </div>
                </div>
                <div ref={contentContainerRef}
                     className={styles.ContentContainer}
                     hidden={true}>
                    <div className={styles.Back}
                         onClick={onBack}>
                        <ArrowBackIcon className={styles.Icon}/>
                        <Headline contentMargin={'0 0 0 10px'}
                                  contentFontFamily={'Inter, sans-serif'}
                                  contentFontWeight={'var(--body1-bold-font-weight)'}
                                  contentFontSize={'var(--body1-bold-font-size)'}
                                  contentLineHeight={'var(--body1-bold-line-height)'}
                                  contentColor={'var(--090-black)'}>
                            {localizations.clientsCertificatesBack}
                        </Headline>
                    </div>
                    <Headline contentMargin={'25px 0 0'}
                              contentFontSize={'var(--display3-font-size)'}
                              contentLineHeight={'var(--display3-line-height)'}>
                        {localizations.clientsCertificatesFound.replaceAll('{size}', clientsCertificates.length)}
                    </Headline>
                    <div className={styles.SearchInfoContainer}>
                        {
                            inn && <div className={styles.SearchInfo}>
                                <SearchInfoIcon className={styles.Icon}/>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.clientsCertificatesSearchInfoInn.replaceAll('{inn}', inn)}
                                </Row>
                            </div>
                        }
                        {
                            edrpou && <div className={styles.SearchInfo}>
                                <SearchInfoIcon className={styles.Icon}/>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.clientsCertificatesSearchInfoEdrpou.replaceAll('{edrpou}', edrpou)}
                                </Row>
                            </div>
                        }
                        {
                            serialNumber && <div className={styles.SearchInfo}>
                                <SearchInfoIcon className={styles.Icon}/>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.clientsCertificatesSearchInfoSerialNumber.replaceAll('{serialNumber}', serialNumber)}
                                </Row>
                            </div>
                        }
                        {
                            withRevoked && <div className={styles.SearchInfo}>
                                <SearchInfoIcon className={styles.Icon}/>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.clientsCertificatesSearchInfoWithRevoked}
                                </Row>
                            </div>
                        }
                    </div>
                    {
                        clientsCertificates.length > 0 && <div className={styles.MultiCheckBox}>
                            <CheckBox id={0}
                                      ref={multiplyCheckBoxRef}
                                      onChange={onChangeMultiCheckBox}/>
                            <div className={styles.Text}>
                                <Row horizontal={true}
                                     contentFontSize={'var(--body2-font-size)'}
                                     contentLineHeight={'var(--body2-line-height)'}>
                                    {localizations.clientsCertificatesMultiCheckBox}
                                </Row>
                            </div>
                        </div>
                    }
                    <div className={styles.Content}>
                        {
                            clientsCertificates.length > 0 && clientsCertificates.map(displayedClientsCertificate => {
                                return (
                                    <div key={displayedClientsCertificate.index}
                                         className={styles.ClientsCertificate}>
                                        <div
                                            id={certificateIndexPattern.replace('{index}', displayedClientsCertificate.index.toString())}
                                            className={styles.Card}>
                                            <div className={styles.Row}>
                                                <div className={styles.Left}>
                                                    <CheckBox id={displayedClientsCertificate.index}
                                                              ref={(element) => singleCheckBoxRefs.current[displayedClientsCertificate.index] = element}
                                                              onChange={(event) => onChangeSingleCheckBox(event, displayedClientsCertificate.index)}/>
                                                </div>
                                                <div className={styles.Right}>
                                                    <Headline contentMargin={'0'}
                                                              contentFontFamily={'Inter, sans-serif'}
                                                              contentFontWeight={'var(--body2-bold-font-weight)'}
                                                              contentFontSize={'var(--body2-bold-font-size)'}
                                                              contentLineHeight={'var(--body2-bold-line-height)'}
                                                              contentLetterSpacing={'var(--body2-bold-letter-spacing)'}>
                                                        {`${displayedClientsCertificate.owner} ${displayedClientsCertificate.orgName}`}
                                                    </Headline>
                                                </div>
                                            </div>
                                            <div className={styles.Row}>
                                                <div className={styles.Left}>
                                                    <Headline contentMargin={'0'}
                                                              contentFontFamily={'Inter, sans-serif'}
                                                              contentFontWeight={'var(--caption-bold-font-weight)'}
                                                              contentFontSize={'var(--caption-bold-font-size)'}
                                                              contentLineHeight={'var(--caption-bold-line-height)'}
                                                              contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                                              contentColor={'var(--050-black)'}>
                                                        {localizations.clientsCertificatesSerial}
                                                    </Headline>
                                                </div>
                                                <div className={styles.Right}>
                                                    <Row horizontal={true}
                                                         contentFontSize={'var(--body2-font-size)'}
                                                         contentLineHeight={'var(--body2-line-height)'}>
                                                        {displayedClientsCertificate.serial}
                                                    </Row>
                                                </div>
                                            </div>
                                            <div className={styles.Row}>
                                                <div className={styles.Left}>
                                                    <Headline contentMargin={'0'}
                                                              contentFontFamily={'Inter, sans-serif'}
                                                              contentFontWeight={'var(--caption-bold-font-weight)'}
                                                              contentFontSize={'var(--caption-bold-font-size)'}
                                                              contentLineHeight={'var(--caption-bold-line-height)'}
                                                              contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                                              contentColor={'var(--050-black)'}>
                                                        {localizations.clientsCertificatesStatus}
                                                    </Headline>
                                                </div>
                                                <div className={styles.Right}>
                                                    <div className={styles.Flex}>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {
                                                                displayedClientsCertificate.status === 3
                                                                    ? localizations.clientsCertificatesStatusBlocked
                                                                    : displayedClientsCertificate.status === 2
                                                                        ? localizations.clientsCertificatesStatusRevoked
                                                                        : displayedClientsCertificate.status === 4
                                                                            ? localizations.clientsCertificatesStatusExpired
                                                                            : localizations.clientsCertificatesStatusActive
                                                            }
                                                            &nbsp;
                                                        </Row>
                                                        {
                                                            displayedClientsCertificate.isRevoked && displayedClientsCertificate.status !== 4 && (
                                                                <Row
                                                                    horizontal={true}
                                                                    contentFontSize="var(--body2-font-size)"
                                                                    contentLineHeight="var(--body2-line-height)">
                                                                    {displayedClientsCertificate.revokedDate}
                                                                </Row>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.Row}>
                                                <div className={styles.Left}>
                                                    <Headline contentMargin={'0'}
                                                              contentFontFamily={'Inter, sans-serif'}
                                                              contentFontWeight={'var(--caption-bold-font-weight)'}
                                                              contentFontSize={'var(--caption-bold-font-size)'}
                                                              contentLineHeight={'var(--caption-bold-line-height)'}
                                                              contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                                              contentColor={'var(--050-black)'}>
                                                        {localizations.clientsCertificatesValidity}
                                                    </Headline>
                                                </div>
                                                <div className={styles.Right}>
                                                    <div className={styles.Flex}>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {displayedClientsCertificate.startDate}
                                                            &nbsp;-&nbsp;
                                                        </Row>
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {displayedClientsCertificate.endDate}
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.Row}>
                                                <div className={styles.Left}>
                                                    <Headline contentMargin={'0'}
                                                              contentFontFamily={'Inter, sans-serif'}
                                                              contentFontWeight={'var(--caption-bold-font-weight)'}
                                                              contentFontSize={'var(--caption-bold-font-size)'}
                                                              contentLineHeight={'var(--caption-bold-line-height)'}
                                                              contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                                              contentColor={'var(--050-black)'}>
                                                        {localizations.clientsCertificatesType}
                                                    </Headline>
                                                </div>
                                                <div className={styles.Right}>
                                                    <Row horizontal={true}
                                                         contentFontSize={'var(--body2-font-size)'}
                                                         contentLineHeight={'var(--body2-line-height)'}>
                                                        {
                                                            displayedClientsCertificate.isSign
                                                                ? localizations.clientsCertificatesTypeSign
                                                                : localizations.clientsCertificatesTypeStamp
                                                        }
                                                    </Row>
                                                </div>
                                            </div>
                                            <div className={styles.Row}>
                                                <div className={styles.Left}>
                                                    <Headline contentMargin={'0'}
                                                              contentFontFamily={'Inter, sans-serif'}
                                                              contentFontWeight={'var(--caption-bold-font-weight)'}
                                                              contentFontSize={'var(--caption-bold-font-size)'}
                                                              contentLineHeight={'var(--caption-bold-line-height)'}
                                                              contentLetterSpacing={'var(--caption-bold-letter-spacing)'}
                                                              contentColor={'var(--050-black)'}>
                                                        {localizations.clientsCertificatesKey}
                                                    </Headline>
                                                </div>
                                                <div className={styles.Right}>
                                                    <div className={styles.Inline}>
                                                        {
                                                            displayedClientsCertificate.type === 2
                                                                ? displayedClientsCertificate.isSign
                                                                    ? <SmartIdSignIcon className={styles.Icon}/>
                                                                    : <SmartIdStampIcon className={styles.Icon}/>
                                                                : displayedClientsCertificate.isSign
                                                                    ? <JksSignIcon className={styles.Icon}/>
                                                                    : <JksStampIcon className={styles.Icon}/>
                                                        }
                                                        &nbsp;
                                                        <Row horizontal={true}
                                                             contentFontSize={'var(--body2-font-size)'}
                                                             contentLineHeight={'var(--body2-line-height)'}>
                                                            {
                                                                displayedClientsCertificate.type === 2
                                                                    ? displayedClientsCertificate.isSign
                                                                        ? localizations.clientsCertificatesSignSmartID
                                                                        : localizations.clientsCertificatesStampSmartID
                                                                    : displayedClientsCertificate.isSign
                                                                        ? localizations.clientsCertificatesSignJKS
                                                                        : localizations.clientsCertificatesStampJKS
                                                            }
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    {
                        clientsCertificates.length > 0 && <div className={styles.ActionPanel}>
                            <Row topLine={true}
                                 disabledIcon={true}/>
                            <div className={styles.Container}>
                                <div className={styles.Shake}>
                                    <span className={styles.Checked}>
                                        {localizations.clientsCertificatesChecked}
                                    </span>
                                    <span>&nbsp;</span>
                                    <span ref={checkedRef}
                                          className={styles.Checked}>
                                        {certificatesChecked ? certificatesChecked.length : 0}
                                    </span>
                                    <span>&nbsp;</span>
                                    <span className={styles.CheckedMax}>
                                        {localizations.clientsCertificatesCheckedMax.replaceAll('{max}', maxSelectCertificates.toString())}
                                    </span>
                                </div>
                                <div className={styles.ActionsMenu}>
                                    <div className={styles.Action}
                                         onClick={onDownloadCertificates}>
                                        <DownloadCertificatesIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.clientsCertificatesDownloadCertificatesAction}
                                        </Row>
                                    </div>
                                    <div className={styles.Action}
                                         onClick={onDownloadPdfs}>
                                        <DownloadPdfsIcon className={styles.Icon}/>
                                        <Row horizontal={true}
                                             contentFontSize={'var(--body2-font-size)'}
                                             contentLineHeight={'var(--body2-line-height)'}>
                                            {localizations.clientsCertificatesDownloadPdfsAction}
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div ref={processingRef}
                     className={styles.Processing}
                     hidden={true}>
                    <div className={styles.Blur}>
                        <LoaderAnimation className={styles.Animation}/>
                    </div>
                </div>
            </MobileOnlyView>
        </div>
    );
}

ClientsCertificates.propTypes = {
    activeLanguage: PropTypes.string.isRequired,
    openCertificatesNavigation: PropTypes.func.isRequired,
    closeCertificatesNavigation: PropTypes.func.isRequired,
    errorGetClientCertificates: PropTypes.func.isRequired,
    errorGetCertificates: PropTypes.func.isRequired,
    errorGetPdf: PropTypes.func.isRequired,
}

export default ClientsCertificates;
