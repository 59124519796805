import React from 'react';
import {useLocation} from 'react-router-dom';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {CERTIFICATES_CLIENTS_PAGE_PATH} from "../../paths/pagePaths";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import localizations from "../../localizations/localizations";

function ReCaptchaWrapper({children}) {
    const location = useLocation();
    const showRecaptcha = location.pathname === CERTIFICATES_CLIENTS_PAGE_PATH;
    const [language] = useLocalStorage('language',
        "uk",
        (language) => language && localizations.setLanguage(language.toLowerCase()),
        (language) => language && localizations.setLanguage(language.toLowerCase()));

    return (
        <>
            {showRecaptcha && (
                <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_NEW}
                                         language={language}>
                    {children}
                </GoogleReCaptchaProvider>
            )}
            {!showRecaptcha && <>{children}</>}
        </>
    );
}

export default ReCaptchaWrapper;