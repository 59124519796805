import React from 'react';
import styles from './SmartID.module.css';
import localizations from '../../localizations/localizations';
import {ReactComponent as SmartIdDarkLogo} from './smartIdDarkLogo.svg';
import BrowserView from '../../views/BrowserView';
import TabletView from '../../views/TabletView';
import MobileOnlyView from '../../views/MobileOnlyView';
import Intro from '../Intro/Intro';
import Headline from '../Headline/Headline';
import Row from '../Row/Row';

function SmartID() {
    return (
        <div className={styles.SmartID}>
            <BrowserView viewClassName={styles.Browser}>
                <Intro>
                    <div className={styles.Info}>
                        <a href={process.env.REACT_APP_SMART_ID_LINK}
                           className={styles.Logo}
                           target='_blank'
                           rel='noopener noreferrer'>
                            <SmartIdDarkLogo/>
                        </a>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--subheader1-font-size)'}
                                 contentLineHeight={'var(--subheader1-line-height)'}>
                                {localizations.smartIdIntro}
                            </Row>
                        </div>
                    </div>
                </Intro>
                <div className={styles.Content}>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.smartIdContentInstructions}
                    </Headline>
                    <Row topLine={true}
                         download={true}
                         bottomLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Договір_про_підключення_інформаційно-комунікаційної_системи_Учасника_до_Сервісу_«SmartID»_АТ_КБ_«ПриватБанк»_v2.docx'}>
                        {localizations.smartIdContentInstruction1}
                    </Row>
                    <Row topLine={false}
                         download={true}
                         bottomLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Заява_про_приєднання_до_Договору_про_підключення_інформаційно-комунікаційної_системи_Учасника_до_Сервісу_«SmartID»_АТ_КБ_«ПриватБанк»_v2.docx'}>
                        {localizations.smartIdContentInstruction2}
                    </Row>
                    <Row topLine={false}
                         download={true}
                         bottomLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/SmartID.pdf'}>
                        {localizations.smartIdContentInstruction3}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.smartIdContentLibraries}
                    </Headline>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-Java-20231115.zip'}>
                        {localizations.smartIdContentLibrary1}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-CS-20231031.zip'}>
                        {localizations.smartIdContentLibrary2}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-JS-20230806.zip'}>
                        {localizations.smartIdContentLibrary3}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-CS-NetCore-20231124.zip'}>
                        {localizations.smartIdContentLibrary4}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSPHPE-20231024.zip'}>
                        {localizations.smartIdContentLibrary5}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-Linux-Python-20231027.zip'}>
                        {localizations.smartIdContentLibrary6}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-MSWindows-Python-20231027.zip'}>
                        {localizations.smartIdContentLibrary7}
                    </Row>
                </div>
                <Headline contentMargin={'0 0 50px'}
                          contentFontSize={'var(--subheader2-font-size)'}
                          contentLineHeight={'var(--subheader2-line-height)'}
                          contentColor={'var(--090-black)'}>
                    {localizations.smartIdContactInformation}
                    &nbsp;
                    <a className={styles.Link}
                       href={'mailto:smartid@privatbank.ua'}>
                        {localizations.smartIdContactInformationMail}
                    </a>
                </Headline>
            </BrowserView>
            <TabletView viewClassName={styles.Tablet}>
                <Intro>
                    <div className={styles.Info}>
                        <a href={process.env.REACT_APP_SMART_ID_LINK}
                           className={styles.Logo}
                           target='_blank'
                           rel='noopener noreferrer'>
                            <SmartIdDarkLogo/>
                        </a>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--subheader1-font-size)'}
                                 contentLineHeight={'var(--subheader1-line-height)'}>
                                {localizations.smartIdIntro}
                            </Row>
                        </div>
                    </div>
                </Intro>
                <div className={styles.Content}>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.smartIdContentInstructions}
                    </Headline>
                    <Row topLine={true}
                         download={true}
                         bottomLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Договір_про_підключення_інформаційно-комунікаційної_системи_Учасника_до_Сервісу_«SmartID»_АТ_КБ_«ПриватБанк»_v2.docx'}>
                        {localizations.smartIdContentInstruction1}
                    </Row>
                    <Row topLine={false}
                         download={true}
                         bottomLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Заява_про_приєднання_до_Договору_про_підключення_інформаційно-комунікаційної_системи_Учасника_до_Сервісу_«SmartID»_АТ_КБ_«ПриватБанк»_v2.docx'}>
                        {localizations.smartIdContentInstruction2}
                    </Row>
                    <Row topLine={false}
                         download={true}
                         bottomLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/SmartID.pdf'}>
                        {localizations.smartIdContentInstruction3}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.smartIdContentLibraries}
                    </Headline>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-Java-20231115.zip'}>
                        {localizations.smartIdContentLibrary1}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-CS-20231031.zip'}>
                        {localizations.smartIdContentLibrary2}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-JS-20230806.zip'}>
                        {localizations.smartIdContentLibrary3}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-CS-NetCore-20231124.zip'}>
                        {localizations.smartIdContentLibrary4}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSPHPE-20231024.zip'}>
                        {localizations.smartIdContentLibrary5}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-Linux-Python-20231027.zip'}>
                        {localizations.smartIdContentLibrary6}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-MSWindows-Python-20231027.zip'}>
                        {localizations.smartIdContentLibrary7}
                    </Row>
                </div>
                <Headline contentMargin={'0 0 50px'}
                          contentFontSize={'var(--subheader2-font-size)'}
                          contentLineHeight={'var(--subheader2-line-height)'}
                          contentColor={'var(--090-black)'}>
                    {localizations.smartIdContactInformation}
                    &nbsp;
                    <a className={styles.Link}
                       href={'mailto:smartid@privatbank.ua'}>
                        {localizations.smartIdContactInformationMail}
                    </a>
                </Headline>
            </TabletView>
            <MobileOnlyView viewClassName={styles.Mobile}>
                <Intro>
                    <div className={styles.Info}>
                        <a href={process.env.REACT_APP_SMART_ID_LINK}
                           className={styles.Logo}
                           target='_blank'
                           rel='noopener noreferrer'>
                            <SmartIdDarkLogo/>
                        </a>
                        <div className={styles.Text}>
                            <Row horizontal={true}
                                 contentFontSize={'var(--subheader2-font-size)'}
                                 contentLineHeight={'var(--subheader2-line-height)'}>
                                {localizations.smartIdIntro}
                            </Row>
                        </div>
                    </div>
                </Intro>
                <div className={styles.Content}>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.smartIdContentInstructions}
                    </Headline>
                    <Row topLine={true}
                         download={true}
                         bottomLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Договір_про_підключення_інформаційно-комунікаційної_системи_Учасника_до_Сервісу_«SmartID»_АТ_КБ_«ПриватБанк»_v2.docx'}>
                        {localizations.smartIdContentInstruction1}
                    </Row>
                    <Row topLine={false}
                         download={true}
                         bottomLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/Заява_про_приєднання_до_Договору_про_підключення_інформаційно-комунікаційної_системи_Учасника_до_Сервісу_«SmartID»_АТ_КБ_«ПриватБанк»_v2.docx'}>
                        {localizations.smartIdContentInstruction2}
                    </Row>
                    <Row topLine={false}
                         download={true}
                         bottomLine={true}
                         link={'https://acsk.privatbank.ua/arch/docs/SmartID.pdf'}>
                        {localizations.smartIdContentInstruction3}
                    </Row>
                    <Headline contentMargin={'45px 0 30px'}>
                        {localizations.smartIdContentLibraries}
                    </Headline>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-Java-20231115.zip'}>
                        {localizations.smartIdContentLibrary1}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-CS-20231031.zip'}>
                        {localizations.smartIdContentLibrary2}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-JS-20230806.zip'}>
                        {localizations.smartIdContentLibrary3}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-CS-NetCore-20231124.zip'}>
                        {localizations.smartIdContentLibrary4}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSPHPE-20231024.zip'}>
                        {localizations.smartIdContentLibrary5}
                    </Row>
                    <Row topLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-Linux-Python-20231027.zip'}>
                        {localizations.smartIdContentLibrary6}
                    </Row>
                    <Row topLine={true}
                         bottomLine={true}
                         download={true}
                         link={'https://acsk.privatbank.ua/arch/iitlib/EUSignCP-MSWindows-Python-20231027.zip'}>
                        {localizations.smartIdContentLibrary7}
                    </Row>
                </div>
                <div className={styles.ContactMobile}>
                    {localizations.smartIdContactInformation}
                    <a className={styles.Link}
                       href={'mailto:smartid@privatbank.ua'}>
                        {localizations.smartIdContactInformationMail}
                    </a>
                </div>
            </MobileOnlyView>
        </div>
    );
}

export default SmartID;
